.line {
  display: inline-block;
  width: 1px;
  height: 24px;
  background-color: var(--gray-150);
  margin-right: 100px;
}

.nav-link {
  color: black;
  letter-spacing: 0.3px;
}

.nav-link:hover {
  color: #a8a8a8;
}

.navbar-brand {
  margin: 0;
}

.navbar-collapse.show {
  height: calc(100vh - 72px) !important;
}


.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler {
  border-color: rgba(0,0,0,0) !important;
}

.navbar-toggler-icon {
  background-image: none;
  width: 25px;
  height: 2px;
  margin-top: -3px;
  background-color: black;
  border-radius: 25px;
  position: relative;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.navbar-toggler-icon::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: -8px;
  background-color: black;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.navbar-toggler-icon::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  bottom: -8px;
  background-color: black;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}




.logo {
  width: 100px;
  height: auto;
}

@media (max-width: 992px) {
  .order {
    order: -1;
  }
}
